::ng-deep {
    .onboarding-dip + .modal-backdrop {
        background-color: map-get($theme, background);
    }
}

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    color: map-get($modal, backdrop);
    // color it's actualy bg-color for backdrop to easily inherit, actual text color is controled by modal's dialog color prop

    &.show {
        @include flexbox();
        @include flex-flow(row nowrap);
        @include align-items(flex-start);
        z-index: map-get($z-index, modal);
    }

    &.animating {
        overflow: hidden;
        pointer-events: none;
    }
}

.modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: currentColor;

    &.dismissable { cursor: pointer; }
}

.modal-dialog {
    z-index: 1;
    align-self: center;
    margin: auto;
    position: relative;
    color: map-get($modal, color);
    font-size: map-get($modal, font-size);
    max-width: 100%;
    max-height: 100%;

    &.flow {
        overflow: hidden;
        background: map-get($modal, background);
        box-shadow: map-get($modal, shadow);
    }

    &.single:not(.video-dialog) {
        .modal-component {
            background: map-get($modal, background);
            box-shadow: map-get($modal, shadow);
        }
    }

    .modal-component {
        display: block;
        border-radius: inherit;
        max-width: 100vw;
        max-height: 100vh;
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);

        @include breakpoint_from(sm) {
            &.size-sm {
                width: 440px;
            }

            &.size-md {
                width: 600px;
            }

            &.size-lg {
                width: 790px;
            }

            &.size-xl {
                width: 880px;
            }

            &.size-xxl {
                width: 960px;
            }

            &.size-auto {
                width: auto;
            }
        }
    }

    // video modal custom dialog
    &.video-dialog {

        height: auto;

        .modal-close {
            position: relative;
            display: block;
            margin-left: auto;
            color: theme-color(--light);
        }
    }

    // bottom-sheet type
    &.bottom-sheet {
        @include breakpoint_to('sm') {
            height: auto;
            margin-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &.light-close {
        .modal-close { color: theme-color(--light);}
    }
}

// mobile
@include breakpoint_to(sm) {
    .modal-dialog {
        width: 100%;
        height: 100%;

        .modal-component {
            width: 100%;
            height: 100%;
        }
    }
}


/// Modal Styling
.modal {

    .modal-close {
        width: 40px;
        font-size: 16px;
        position: absolute;
        right: 0;
        top: env(safe-area-inset-top);
        z-index: 2;
    }

    .modal-content {
        @include flexbox;
        @include flex-flow(column nowrap);
        @include flex-basis(100%);
        height: 100%;
        max-height: 100vh;
    }

    .modal-header, .modal-body, .modal-footer {
        padding: map-get($modal, padding);
    }

    .modal-header {
        flex-shrink: 0;
        &.bordered { border-bottom: 1px solid map-get($modal, divider); }
        &:not(.bordered) { padding-bottom: 0; }
    }

    .modal-body {
        @include flex-grow(1);
        @include flex-shrink(1);
        overflow-y: auto;

        &.centered {
            margin-top: auto;
            margin-bottom: auto;
            @include flex-grow(0);
        }
    }

    .modal-footer {
        flex-shrink: 0;
        &.bordered { border-top: 1px solid map-get($modal, divider); }
        .btn { min-width: 110px;}
        .btn-extend{ width: 100%; }
    }

    .modal-title {
        margin: 0;
        font-weight: 500;
        line-height: 1em;
    }

    .bg-gray {
        background-color: theme-color(--shade-5);
    }

    .shadowed {
        box-shadow: inset 0 2px 8px -2px rgba(0,0,0,0.1);
    }

    // treated in _typography

    /// modal styles depending on breakpoints
    @each $breakpoint, $map in map-get($modal, breakpoints) {
        @include breakpoint-from($breakpoint){

            .modal-dialog {
                border-radius: map-get($map, border-radius);
            }

            .modal-header, .modal-body, .modal-footer {
                padding: map-get($map, padding);
            }

            .modal-footer .btn + .btn {
                margin-left: calc(map-get($map, padding) / 2);
            }

        }
    }
}

// new ilustrated modal type
.modal-dialog .illustrated-modal-container {
    @include flexbox;
    @include flex-flow(row nowrap);
    @include align-items(stretch);
    height: calc(100% + env(safe-area-inset-top));
    margin-top: calc(env(safe-area-inset-top) * -1);

    &.flex-column {
        @include flex-direction(column);
    }

    .modal-illustration {
        @include flex-basis(50%);
        @include flex-shrink(0);
        @include flex-grow(0);
        overflow: hidden;
        position: relative;

        .illustration {
            max-width: 100%;
        }
    }

    .modal-content {
        @include flex-basis(100%);
        @include flex-shrink(1);
        @include flex-grow(1);
    }
}


