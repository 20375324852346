/// Style for the form components

%inputBox {
    @include flexbox;
    @include flex-flow(row nowrap);
    @include align-items(center);
    margin: 0;
    color: map-get($form, colors, input);

    &.boxed {
        border-style: solid;
        border-width: map-get($form, border-width);
        border-radius: map-get($form, border-radius);
        border-color: map-get($form, colors, border);
        background-color: map-get($form, colors, background);
    }
}

%inputField {
    width: 100%;
    margin: 0;
    padding-left: 12px;
    padding-right: 12px;
    color: inherit;
    border: none;
    border-radius: inherit;
    background: none;
    caret-color: currentColor;
    text-align: inherit;
    letter-spacing: inherit;
    font-family: inherit;
    outline: none;

    &::placeholder { color: map-get($form, colors, placeholder); opacity: 1; }
    &:-ms-input-placeholder { color: map-get($form, colors, placeholder); }
    &::-ms-input-placeholder { color: map-get($form, colors, placeholder); }
}

%inputLabel {
    color: map-get($form, label, color);
    font-size: map-get($form, label, font-size);
    line-height: map-get($form, label, line-height);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// this label is used for radios, checkboxes and switch coontrols, it has to look as much as regular text as possible
%inlineLabel {
    color: map-get($form, inlineLabel, color);
    font-size: map-get($form, inlineLabel, font-size);
    line-height: map-get($form, inlineLabel, line-height);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

%hint {
    color: map-get($form, hint, color);
    font-size: map-get($form, hint, font-size);
    line-height: map-get($form, hint, line-height);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// input sizes, applicable to inputs, selects and textareas
@each $sizeClass, $size in map-get($form, size) {
    %#{$sizeClass} {
        font-size: map-get($size, font-size);
        line-height: map-get($size, line-height);
        padding-top: calc((map-get($size, height) - map-get($size, line-height)) / 2) - map-get($form, border-width);
        padding-bottom: calc((map-get($size, height) - map-get($size, line-height)) / 2) - map-get($form, border-width);
    }
}

fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

// to hide elements but keep them available for screen readers
*[sr-only] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

// form-control common styling input-control, select-control, textarea control
.form-control {

    display: inline-block; // this is important, do not alter
    margin-bottom: 2 * $spas;

    label {
        @extend %inputLabel;
        margin-bottom: calc($spas / 2);
        display: inline-block;
    }

    .hint {
        @extend %hint;
        margin-top: calc($spas / 2);
    }

    input, select, textarea {
        @extend %inputField;
    }

    .input-control, .select-control, .textarea-control {
        @extend %inputBox;
    }

    // sizes
    @each $sizeClass, $size in map-get($form, size) {
        &.#{$sizeClass} {
            input, select, textarea {
                @extend %#{$sizeClass};
            }

            select {
                padding-right: map-get($size, height);
            }
            .select-control > .icon {
                width: map-get($size, height);
                height: map-get($size, height);
                line-height: map-get($size, height);
                margin: 0;
            }

            .select-control .option {
                height: map-get($size, height);
                font-size: map-get($size, font-size);
            }

            &.pill .input-control {
                border-radius: map-get($size, height);
            }
        }
    }

    @each $stateClass, $state in map-get($form, states) {
        &.#{$stateClass} {
            .input-control, .select-control, .textarea-control {
                color: map-get($state, color);
                border-color: map-get($state, border-color);
                background-color: map-get($state, background-color);
            }

            .hint { color: map-get($state, hint); }
        }
    }

    &.focused {
        label { color: map-get($form, colors, focus); }
        .input-control, .select-control, .textarea-control {  border-color: map-get($form, colors, focus); }
    }
}

.input-control {
    position: relative;

    .suffix, .prefix {
        @include flexbox();
        height: 100%;
        align-items: center;
        justify-content: center;
        line-height: 1em;
        z-index: 2;
        margin-top: -1px;
        margin-bottom: -1px;
    }

    .suffix {
        order: 12;
        margin-right: -1px;
    }

    .prefix {
        order: -1;
        margin-left: -1px;
    }

    &.prefixed input {
        padding-left: 8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &.suffixed input {
        padding-right: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.select-control {

    position: relative;
    width: 100%;

    > .icon {
        cursor: pointer;
        position: absolute;
        text-align: center;
        right: 0;
        top: 50%;
        transform: rotate(-90deg) translateX(50%);
        font-size: 13px;
        color: theme-color(--shade-40);
        pointer-events: none;
    }

    input {
        padding-right: 48px;
    }

    select {
        z-index: 1;
        -webkit-appearance: none;
        appearance: none;
        text-overflow: ellipsis;

         // special select with thumb
        &.withThumb, &.withThumb + .select-options .option {
            background-repeat: no-repeat;
            background-position: $spas;
            background-size: 24px;
            padding-left: 40px;
        }
    }

    select::-ms-expand { display: none; }

    .select-options {
        position: absolute;
        z-index: 0;
        // width: 100%;
        left: 0;
        overflow-y: auto;
        height: 0;
        min-width: calc(100% - 2 * map-get($form, border-width));
        border-width: map-get($form, border-width);
        border-style: solid;
        border-color: theme-color(--primary);
        border-radius: map-get($button, border-radius);
        box-shadow: 0px 2px 8px 2px rgba(0, 94, 255, 0.1);
        background-color: map-get($form, colors, background);
        box-sizing: content-box;

        &.down {
            top: 100%;
            margin-top: 1px;
        }

        &.up {
            bottom: 100%;
            margin-bottom: 1px;
        }

        &.open {
            height: auto;
            z-index: 3;
        }

        .option {
            display: block;
            text-align: left;
            width: 100%;
            padding-left: 2*$spas;
            padding-right: 2*$spas;
            overflow: hidden;
            text-overflow: ellipsis;

            &.active, &.selected {
                background: theme-color(--primary, 0.1);
                padding-right: 36px;

                &:after {
                    content: "";
                    display: block;
                    color: theme-color(--primary);
                    position: absolute;
                    right: 12px;
                    margin-top: -6px;
                    top: 50%;
                    width: 10px;
                    height: 5px;
                    border-style: solid;
                    border-color: currentColor;
                    border-width: 0 0 2px 2px;
                    -webkit-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                }
            }
            &.hidden {
                display: none;
            }

            &[disabled] {
                color: theme-color(--shade-40);
                pointer-events: none;
            }
        }

        .option:hover, .option:focus {
            background: theme-color(--primary, 0.05);
        }

    }

}

.textarea-control {

    textarea {
        height: auto;

        &.no-resize {
            resize: none;
        }
    }
}

/// radio, checkbox, switch common style
/// switch is also a checkbox
.radio-control, .checkbox-control, .switch-control {
    @include flexbox-inline;
    @include align-items(flex-start);
    @include flex-flow(row nowrap);

    position: relative;
    z-index: 0;

    label {
        @extend %inlineLabel;
        padding-top: calc((map-get($form, safe-zone) + map-get($form, check-size) - map-get($form, inlineLabel, line-height))/2);
        padding-bottom: calc((map-get($form, safe-zone) + map-get($form, check-size) - map-get($form, inlineLabel, line-height))/2);
        position: relative;

        &[for] {
            cursor: pointer;
        }
    }

    input {
        position: absolute;
        height: map-get($form, check-size);
        margin: calc(map-get($form, safe-zone)/2) 0;
        z-index: 0;
        opacity: 0;
        cursor: pointer;

        &[disabled] {
            ~ label, ~ .control-div {
                cursor: default;
            }
        }
    }

    .hint {
        @extend %hint;
        margin-top: 0;
    }
}

.checkbox-control, .radio-control {
    input {
        width: map-get($form, check-size);
    }

    label {
        display: block;
    }
    .hint {
        margin: 0;
    }
    .control-label {
        margin-left: map-get($form, safe-zone);
        width: 100%;
    }

    .control-div {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        margin: calc(map-get($form, safe-zone)/2) 0;
        min-width: map-get($form, check-size);
        width: map-get($form, check-size);
        height: map-get($form, check-size);
        background: transparent;

        border-radius: map-get($form, border-radius);
        border-width: map-get($form, border-width);
        border-color: map-get($controls, border-colors, default);
        border-style: solid;
        z-index: -1;
        transition: opacity 0.05s ease-in-out;
        ui-icon {
            opacity: 0;
        }
        position: relative;
    }

    &.invalid {
        .hint { color: map-get($form, states, invalid, hint); }
        .control-div { border-color: map-get($form, states, invalid, hint); }
    }

    input:checked {
        & ~ .control-div {
            background: theme-color(--primary);
            border-width: 0px;
            ui-icon {
                opacity: 1;
            }
        }
    }

    input:focus {
        border-width: map-get($form, outline-width);
        ~ .control-div { border-color: map-get($controls, border-colors, focus); }
        &:checked ~ .control-div {
            background: map-get($form, colors, focus);
            border-color: map-get($controls, border-colors, focus);
        }
    }

    input:hover {
        ~ .control-div { border-color: map-get($controls, border-colors, hover);}
        &:checked ~ .control-div { background: map-get($form, colors, hover-background); }
    }

    input:active {
        ~ .control-div { border-color: map-get($controls, border-colors, tap); }
        &:checked ~ .control-div {
            background: map-get($form, colors, tap-background);
        }
    }

    input:disabled {
        ~ .control-div { border-color: map-get($controls, border-colors, disabled); opacity: 0.4; }
        &:checked ~ .control-div {
            background: map-get($form, colors, disabled-and-on);
            opacity: 0.4;
        }
    }

    &.ltr {
        @include justify-content(flex-start);
        .control-div {
            margin-right: map-get($form, safe-zone);
        }
    }

    &.rtl {
        @include justify-content(flex-end);
        .control-div {
            margin-left: map-get($form, safe-zone);
            @include order(12);
        }
    }
}

.radio-control {
    .control-div {
        border-radius: 50%;
    }
}

.radio-control.image {

    input, div.control-div {
        position: absolute;
        width: 57px;
        height: 57px;
        border-radius: 50%;
    }

    div.control-div {
        border-width: 0px;
        color: theme-color(--primary);
        ui-icon {
            display: none;
        }
        background: transparent;
    }

    div.control-label {
        margin: 0;
    }

    label {
        padding: 0;
        margin: 6px 0;
        border-radius: 50%;
        width: 57px;
        height: 57px;
        border-width: 1px;
        border-color: theme-color(--light);
        border-style: solid;
        .ui-avatar {
            z-index: -1;
        }
    }

    input:checked ~ div.control-div,
    input:active ~ div.control-div  {
        outline: 2px solid currentColor;
        background: transparent;
    }

    &:hover .remove-btn {
        display: block;
    }

    .remove-btn {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(8px, -4px);
    }

}

// switch specific styling
.switch-control {
    $switch-width: 2 * (map-get($form, check-size) - 2 * map-get($form, border-width) - 2px);

    .switch-div {
        @include flexbox;
        @include align-items(center);;
        position: relative;
        width: $switch-width;
        flex: 0 0 $switch-width;
        height: map-get($form, check-size);
        color: map-get($switch, off-state, focus);
        background: currentColor;
        border-radius: map-get($form, check-size);
        margin-top: calc(map-get($form, safe-zone) / 2);
        margin-bottom: calc(map-get($form, safe-zone) / 2);
        box-sizing: border-box;
        transition: color 0.05s ease-in-out;
        z-index: -1;

        .switch-circle {
            margin: 2 * map-get($form, border-width);
            width: calc($switch-width / 2);
            height: calc($switch-width / 2);
            border-radius: 50%;
            background: theme-color(--light);
            transition: transform 0.05s ease-in-out;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon-off {
                display: flex;
                color: map-get($switch, icons, off);
            }
            .icon-on {
                display: none;
                color: map-get($switch, icons, on);
            }
        }
    }

    label {
        @include flex-grow(1);
        @include flex-shrink(1);
        @include flex-basis(100%);
    }

    input {
        width: $switch-width;
        // common to both on and off states
        &:focus:not(:active) ~ .switch-div {
            outline: map-get($form, outline-width) solid map-get($form, colors, outline);
            outline-offset: - map-get($form, outline-width);
        }

        &:checked ~ .switch-div{
            background: map-get($form, colors, focus);
            .switch-circle {
                transform: translateX($switch-width - (map-get($form, check-size)));
                .icon-off {
                    display: none;
                }
                .icon-on {
                    display: flex;
                }
            }
        }
    }

    input:hover {
        ~ .switch-div { background: map-get($switch, off-state, hover);}
        &:checked ~ .switch-div { background: map-get($form, colors, hover-background); }
    }

    input:active {
        ~ .switch-div { background: map-get($switch, off-state, tap); }
        &:checked ~ .switch-div { background: map-get($form, colors, tap-background); }
    }

    input:disabled {
        ~ .switch-div { background: map-get($switch, off-state, disabled); opacity: 0.4; }
        &:checked ~ .switch-div {
            background: map-get($form, colors, disabled-and-on);
            opacity: 0.4;
        }
    }

    &.ltr {
        @include justify-content(flex-start);

        .switch-div {
            margin-right: map-get($form, safe-zone);
        }
    }

    &.rtl {
        @include justify-content(flex-end);

        .switch-div {
            margin-left: map-get($form, safe-zone);
            @include order(12);
        }
    }
}

.uploadfile-control {
    margin-bottom: 40px;
    display: block;
    .upload-box {
        min-height: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #f5f5f5;
        padding: 24px;
    }

    &.dragover {
        .upload-box {
            opacity: .7;

        }
    }

    label {
        @extend %inputLabel;
        padding-bottom: 2*$spas;
        display: block;
    }

    .placeholder {
        text-decoration: underline;
        font-size: 18px;
        color: #6A6C72;
        cursor:pointer;
    }

    &.hasfiles {
        .placeholder {
            display:none;
        }

        .upload-box {
            justify-content: flex-start;
        }

        .file-list {
            display: block;
        }
    }

    .error {
        color: theme-color(--danger);
        margin-top: 4px;
    }

    .file-list {
        list-style-type:none;
        margin: 0;
        padding: 0 24px;
        flex: 1;
        display: none;

        li {
            display: flex;
        }

        .file {
            flex: 0 1 30%;
        }

        .delete-file {
            font-size: 0.8em;

            i {
                font-size: 0.8em;
            }
        }

    }
}

// range form control
@mixin rangeThumb {
    width: map-get($range-slider, thumb-size);
    height: map-get($range-slider, thumb-size);
    border-radius: map-get($range-slider, thumb-size);
    background: currentColor;
}

.range-slider {
    display: inline-block;

    label {
        @extend %inputLabel;
        display: block;
    }

    .slider-wrapper {
        @include flexbox();
        @include flex-direction(row);

        span {
            padding-left: $spas;
            white-space: nowrap;
        }
    }

    .slider {
        position: relative;
        width: 100%;
        height: map-get($range-slider, thumb-size);

        input[type=range] {
            -webkit-appearance: none;
            width: 100%;
            height: 100%;
            margin: 0;
            color: inherit;
            background: transparent;
            cursor: pointer;
            position: relative;

            &::-ms-track {
                width: 100%;
                cursor: pointer;
                background: transparent;
                border-color: transparent;
                color: transparent;
            }

            // thumb styling (nu mmerg grupate si nu merg cu @extend)
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                @include rangeThumb;
            }

            &::-moz-range-thumb {
                @include rangeThumb;
            }

            &::-ms-thumb  {
                @include rangeThumb;
            }

            // focus behaviour
            &:focus {
                outline:  none;
            }
        }

        progress {
            pointer-events: none;
            color: inherit;
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            &::-webkit-progress-value { transition: none;}
        }

        &.disabled {
            color: theme-color(--shade-20);
            progress {
                &::-webkit-progress-bar {
                    background-color: theme-color(--shade-5);
                }
                &::-moz-progress-bar {
                    background-color: theme-color(--shade-5);
                }
            }

        }
    }

    // asta doar ca sa avem efect de focus on keyboard tab + culoare mai deschisa pe partea necompletata :(
    @each $color in map-get($range-slider, types) {
        .slider.#{$color} {
            progress {
                &::-webkit-progress-bar {
                    background-color: theme-color(--#{$color}-10);
                }
                &::-moz-progress-bar {
                    background-color: theme-color(--#{$color}-10);
                }
            }

            input[type=range] {color: theme-color(--#{$color});}

            input[type=range]:hover {
                &::-webkit-slider-thumb { background: theme-color(--#{$color}-20); }
                &::-moz-range-thumb  { background: theme-color(--#{$color}-20); }
                &::-ms-thumb { background: theme-color(--#{$color}-20); }
            }

            input[type=range]:focus {
                &::-webkit-slider-thumb { background: theme-color(--#{$color}-30); }
                &::-moz-range-thumb  { background: theme-color(--#{$color}-30); }
                &::-ms-thumb { background: theme-color(--#{$color}-30); }
            }
        }
    }


}
