/// Butons style
%hover {
    &::before {
        background-color: currentColor;
        opacity: 0.1;
    }
}

%active {
    &::before {
        background-color: currentColor;
        opacity: 0.2;
    }
}

%focus {
    box-shadow: 0 0 0 2px inset theme-color(--primary-20);
}

%button {
    background-color: transparent;
    position: relative;
    font-family: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    color: inherit;
    white-space: nowrap; // maybe this should not be default behaviour
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    padding: 0;

    > * { pointer-events: none;}
}

button {
    @extend %button;
    font-size: inherit;

    &::-moz-focus-inner {
        border: 0;
    }
}

.btn {
    @extend %button;
    font-weight: 500;
    border-radius: map-get($button, border-radius);
    [class*="connect_icon"] { vertical-align: middle; }

    &.ellipsis {
        max-width: 100%;
    }
    // button sizes
    @each $class, $size in map-get($button, size) {
        &.#{$class} {
            font-size: map-get($size, font-size);
            line-height: map-get($size, line-height);
            padding: calc((map-get($size, height) - map-get($size, line-height)) / 2) map-get($button, padding);
            &.pill { border-radius: map-get($size, height);}

            // states min-width, is overwritten by icon button's min-width, this css needs to remain this way
            &.solid, &.outline, &.discrete {
                min-width: 2 * map-get($size, height);
            }

            &.icon, &.btn-icon {
                min-width: map-get($size, height);
                min-height: map-get($size, height);
                padding: 0;
            }
        }
    }

    // button label
    .btn-label {
        position: relative;
        width: 100%;
        @include flexbox;
        @include flex-flow(row nowrap);
        justify-content: center;
        align-items: center;
        :not(ui-icon) {
            white-space: inherit;
            overflow: inherit;
            text-overflow: inherit;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        width: 100%;
        height: 100%;
    }

    .ui-icon.left {
        margin-right: calc($spas / 2);
    }

    .ui-icon.right{
        margin-left: calc($spas / 2);
    }

    // disabled button, when disabled all button types look the same
    &[class][disabled] {
        pointer-events: none;
        background-color: theme-color(--shade-5);
        color: theme-color(--shade-50);
        box-shadow: none;
    }

    &:focus {
        @extend %focus;
    }
}

/// solid button style, and it's variations
.solid {
    color: theme-color(--light);
    &:hover::before {
        background:  theme-color(--dark, 0.2);
    }

    &:active::before {
        background:  theme-color(--dark, 0.4);
    }
}

/// outline button style, and it's variations
.outline  {
    box-shadow: 0 0 0 1px inset;

    &:hover {
        @extend %hover;
    }

    &:active {
        @extend %active;
    }
}

/// discrete button style, and it's variations
.discrete  {
    &:hover {
        @extend %hover;
    }

    &:active {
        @extend %active;
    }
}

/// link button style, and it's variations
a.btn.link, .btn.link {
    display: inline-block;
    padding: initial;
    line-height: inherit;

    &:focus, &:hover {
        box-shadow: none;
        text-decoration: underline;
    }
    &[disabled] {  background-color: transparent; }
    i, [class*=connect_icon] {
        &:before{
            display: inline-block;
        }
    }


}

// loading state for buttons waiting on requests
.btn.loading {
    pointer-events: none;
    user-select: none;

    .btn-label, .btn-icon.mid { color: transparent; }

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -0.7em;
        margin-left: -0.7em;
        font-size: 1.1em;
        border-width: 0.2em;
        border-style: solid;
        border-top-color: transparent !important;
        border-radius: 50%;
        width: 1em;
        height: 1em;
        -webkit-animation: spin 0.7s linear infinite;
        animation: spin 0.7s linear infinite;
        box-sizing: content-box;
    }

    &.solid {
        &::after{
            color: theme-color(--light);
        }
        &[disabled]::after{
            color: inherit;
        }
    }
}

a.btn {
    text-decoration: none;
}