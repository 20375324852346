$tag-border: 1px;
.tag {
    display: inline-block;
    position: relative;
    padding: 0px $spas;

    font-weight: 400;
    font-size: map-get($tag, font-size);
    line-height: map-get($tag, height);

    text-align: center;
    vertical-align: middle;

    border-radius: map-get($tag, font-size);
    min-width: map-get($tag, height);

    &:has(.ui-icon.right) {
        padding-right: calc($spas / 2);
    }

    &:has(.ui-icon.left) {
        padding-left: calc($spas / 2);
    }

    &.tag-filled {
        color: theme-color(--light);
    }

    &.tag-outline:before {
        content: '';
        border: $tag-border solid currentColor;
        opacity: 0.2;
        border-radius: inherit;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

    }
    &.tag-outline:after {
        content: '';
        background: currentColor;
        opacity: 0.1;
        border-radius: inherit;
        width: calc(100% + 2 * $tag-border);
        height: calc(100% + 2 * $tag-border);
        position: absolute;
        top: 0;
        left: 0;
    }

    .tag-body {
        @include flexbox;
        @include align-items(center);
        border-radius: inherit;
    }

    .ui-icon.right {
        margin-left: calc($spas / 2);
    }

    .ui-icon.left {
        margin-right: calc($spas / 2);
    }
}