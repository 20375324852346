
.tooltip {
    position: fixed;
    max-width: 280px;
    border: 0 none;
    z-index: map-get($z-index, tooltip);

    .tooltip-content {
        padding: map-get($tooltip, padding);
        display: inline-block;
        position: relative;
        font-size: map-get($tooltip, font-size);
        line-height: map-get($tooltip, line-height);
        border-radius: map-get($tooltip, border-radius);
        width: 100%;

        @include states(map-get($tooltip, types));

        &:before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background-color: inherit;
            transform: rotate(45deg) translate(-70%, -10%);
            position: absolute;
        }
    }


    &.top {
        transform: translate(-50%, -100%);
        margin-top: -$spas;
        .tooltip-content:before{
            top: 100%;
            left: 50%;
        }
    }

    &.bottom {
        transform: translate(-50%, 0%);
        margin-top:  $spas;
        .tooltip-content:before{
            top: 0;
            left: 50%;
        }

    }

    &.left {
        transform: translate(-100%, -50%);
        margin-left: -$spas;
        .tooltip-content:before {
            top: 50%;
            left: 100%;
        }
    }

    &.right {
        transform: translate(0%, -50%);
        margin-left: $spas;
        .tooltip-content:before {
            top: 50%;
            left: 0;
        }
    }
}

.chart-tooltip {
    position: absolute;
    opacity: 1;
    pointer-events: none;
    background-color: theme-color(--primary-5);
    border: 1px solid theme-color(--primary);
    border-radius: $spas;
    padding: $spas;
    .chart-tooltip-title,
    .chart-tooltip-body {
        @include flexbox;
        @include flex-flow(column nowrap);
    }
}