html, body {
    position: relative;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

html {
    min-height: calc(100% + env(safe-area-inset-top));
    overscroll-behavior: none;
    overflow: hidden;
}

body {
    font-family: map-get($theme, font-family);
    font-size: map-get($theme, font-size);
    line-height: map-get($theme, line-height);
    color: map-get($theme, color);
    background-color: map-get($theme, background);
    background-image: map-get($theme, background-image);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: auto;
}

body * {
    box-sizing: border-box;
}

a {
    color: theme-color(--primary);
    cursor: pointer;
    &:hover, &:focus {
        text-decoration: underline;
        outline: none;
    }
}

.standalone {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
